// color variants
@import '_themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ===========================|| APEXCHART ||=========================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ===========================|| PERFECT SCROLLBAR ||=========================== //

.scrollbar-container {
    .ps__rail-y {

        &:hover>.ps__thumb-y,
        &:focus>.ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }

    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container>.ps {
    &.ps--active-y>.ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;

        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }

    &.ps--scrolling-y>.ps__rail-y,
    &.ps--scrolling-x>.ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ===========================|| ANIMATION KEYFRAMES ||=========================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bounce {

    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }

    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {

    0%,
    50%,
    100% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-10px);
    }

    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {

    0%,
    50%,
    100% {
        transform: translateX(0px);
    }

    25% {
        transform: translateX(-10px);
    }

    75% {
        transform: translateX(10px);
    }
}

.ag-header-row.ag-header-row-column {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.ag-header-cell {
    background-color: $primaryLight;
    border: 0.5px solid #80808030;

}

.css-86bacs-MuiButtonBase-root-MuiButton-root:hover {
    background-color: #649528
}

.css-rle582-MuiButtonBase-root-MuiButton-root:hover {
    background-color: #83B941;
}


.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.css-2xhzvc {
    border-radius: 14px !important;
    height: 51px !important;
}

.swiperContainer .swiper-slide {
    width: 150px !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    color: #173e43 !important
}


.makeStyles-drawerPaper-71 {
    top: 80px !important;
}

.map-error {
    color: #f44336;
    font-size: 0.9rem;
}

.back-drop {
    z-index: 1500;
    color: #649528
}

.css-1uup119-MuiAutocomplete-listbox {
    background-color: $grey100
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.css-2xhzvc {
    background-color: $grey100
}

.css-fcpx8l-MuiFormHelperText-root {

    color: rgb(244, 67, 54);
    
    }
.approve-button {
 color:#ffff;
}

.google-map-error-text{
    color: #f44336;
  }
//   .google-map-error.Mui-error {
//     border: 1px solid #f44336;
//     border-radius: 15px;
//   }
  
//   .google-map-error-text.Mui-error {
//     color: #f44336;
//     font-size: 0.9rem;
//   }